import React, { useEffect, useState } from 'react';
import { M10HeroModule } from '~/lib/data-contract';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent';
import { useModule } from '~/templates';
import { ModuleContainer } from '../ModuleContainer';
import { SpotTextBlock } from '../TextBlock';
import { queries, useThemeShade } from '~/theme';
import {
    StyledM10,
    StyledM10Content,
    StyledMediaWrapper,
    StyledParallaxWrapper,
    StyledM10AlignContent,
    StyledM10TextContentWrapper,
} from './styled';
import {
    parallaxInputRange,
    parallaxOutputRangeMedia,
    parallaxOutputRangeTextBlock,
} from './M10HeroSettings';
import { usePage } from '$templates/pages';
import { M10HeroImage } from '$templates/blocks/components/M10Hero/M10HeroImage';
import { M10HeroVideo } from '$templates/blocks/components/M10Hero/M10HeroVideo';

export type M10HeroProps = M10HeroModule;

export const M10Hero = ({
    image,
    video,
    headline,
    subHeadline,
    subHeadlineUppercase,
    text,
    callToAction,
    height: heightTemp,
    hasMediaFilter = false,
    mediaLayout: mediaLayoutTemp,
    mediaHorizontalAlignment = 'left',
    horizontalAlignment = 'left',
    verticalAlignment = 'center',
    spacingTop,
    backgroundColor: initialBackgroundColor,
    hideMobile,
    hideDesktop,
    ...rest
}: M10HeroProps) => {
    const pagesPossibleToHideModule = [
        'p20FrontPage',
        'p40productListPage',
        'p41productListPage',
        'p60ModulePage',
    ];
    const { type } = usePage();
    const mediaLayout = mediaLayoutTemp || 'full';
    const height = heightTemp || 'normal';
    const { index } = useModule();
    const { backgroundColor, backgroundShade, skeletonShade } = useThemeShade({
        backgroundColor: initialBackgroundColor,
    });
    const { marketing, statistic } = useCookieConsent();
    // Because the consent placeholder covers hero text if the layout is full, we fallback to showing image
    const [mediaType, setMediaType] = useState<'video' | 'image'>(
        video?.src && ((marketing && statistic) || mediaLayout !== 'full') ? 'video' : 'image'
    );

    const isFullWidth = mediaLayout === 'full';
    const shouldLinkCover = Boolean(isFullWidth && callToAction?.url);
    const hasMediaGradient =
        hasMediaFilter && isFullWidth && Boolean(headline || subHeadline || text || callToAction);

    useEffect(() => {
        setMediaType(
            video?.src && ((marketing && statistic) || mediaLayout !== 'full') ? 'video' : 'image'
        );
    }, [marketing, statistic, video, mediaLayout, setMediaType]);

    // If verticalAlignment is 'bottom', dont parallax. Avoids possibly going out of bounds.
    const shouldDisableParallaxOnText = verticalAlignment === 'bottom';

    // Reduce image size if above the fold
    const imageSizesM10 = {
        full: index < 2 ? '80vw' : '100vw',
        split: `${queries.md} 67vw, ${index < 2 ? '80vw' : '100vw'}`,
    };
    const imageSizes = imageSizesM10[mediaLayout];

    return (
        <ModuleContainer
            {...rest}
            spacingTop={index === 0 ? 'none' : spacingTop}
            hideMobile={hideMobile && type && pagesPossibleToHideModule.includes(type)}
            hideDesktop={hideDesktop && type && pagesPossibleToHideModule.includes(type)}
        >
            <StyledM10
                height={height}
                mediaLayout={mediaLayout}
                backgroundColor={backgroundColor}
                backgroundShade={backgroundShade}
                index={index}
            >
                <StyledM10Content
                    mediaLayout={mediaLayout}
                    contentAlignment={horizontalAlignment}
                    mediaAlignment={mediaHorizontalAlignment}
                    height={height}
                    title={shouldLinkCover ? callToAction?.title : undefined}
                >
                    <StyledM10TextContentWrapper>
                        <StyledParallaxWrapper
                            disabled={shouldDisableParallaxOnText || mediaLayout === 'split'}
                            offset={['start start', 'end start']}
                            type="instant"
                            inputRange={parallaxInputRange}
                            outputRange={parallaxOutputRangeTextBlock}
                        >
                            <StyledM10AlignContent
                                alignment={horizontalAlignment}
                                position={verticalAlignment}
                            >
                                <SpotTextBlock
                                    headline={headline}
                                    headlineVariant={index === 0 ? 'display1' : 'display2'}
                                    text={text}
                                    subheadline={subHeadline}
                                    subHeadlineUppercase={subHeadlineUppercase}
                                    callToAction={callToAction}
                                    backgroundColor={backgroundColor}
                                    useTranslationForLinkTitle
                                />
                            </StyledM10AlignContent>
                        </StyledParallaxWrapper>
                    </StyledM10TextContentWrapper>
                    {(image || video) && (
                        <StyledMediaWrapper
                            hasGradient={hasMediaGradient}
                            horizontalAlignment={horizontalAlignment}
                            backgroundColor={backgroundColor}
                        >
                            <StyledParallaxWrapper
                                offset={['start start', 'end start']}
                                type="instant"
                                inputRange={parallaxInputRange}
                                outputRange={parallaxOutputRangeMedia}
                            >
                                <M10HeroImage
                                    mediaType={mediaType}
                                    image={image}
                                    isFullWidth={isFullWidth}
                                    index={index}
                                    mediaLayout={mediaLayout}
                                    height={height}
                                    skeletonShade={skeletonShade}
                                    callToAction={callToAction}
                                    sizes={imageSizes}
                                />
                                {mediaType === 'video' && (
                                    <M10HeroVideo
                                        video={video}
                                        isFullWidth={isFullWidth}
                                        callToAction={callToAction}
                                        imageSrc={image?.src}
                                        posterSizes={imageSizes}
                                    />
                                )}
                            </StyledParallaxWrapper>
                        </StyledMediaWrapper>
                    )}
                </StyledM10Content>
            </StyledM10>
        </ModuleContainer>
    );
};

export default M10Hero;
